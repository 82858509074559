export class CookieProvider {
    constructor() {}


    public expiresIn(days: number) {
        const date = new Date();
        return new Date(date.setDate(date.getDate() + days));
    }

    public setToken(token: string): void {
        document.cookie = `token=${token}; expires=${this.expiresIn(7)}`
    }

    public getToken(): string | null {
        let targetCookie = "token=";
        return this.cookieFinder(targetCookie);
    }
    public getSessionId(): string | null {
        let targetCookie = "sessionId=";
        return this.cookieFinder(targetCookie);
    }
    public getWebURL(): string | null {
        let targetCookie = "weburl=";
        return this.cookieFinder(targetCookie);
    }
    public getUserId(): string | null {
        let targetCookie = "userid=";
        return this.cookieFinder(targetCookie);
    }

    private cookieExpiresDate(): string {
      let exipre = new Date();
      exipre.setDate(exipre.getDate() + 30);
      return exipre.toUTCString();
    }
    public set(name: string, value: string = "", expires: string = this.cookieExpiresDate()): void {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
        document.cookie = `${name}=${value}; expires=${expires}`
    }

    public get(name: string = ""): string | null {
        let targetCookie = name +"=";
        return this.cookieFinder(targetCookie);
    }

    public delete(names: string[]): void {
        names.forEach(name => document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`)
    }

    private cookieFinder(target: string) {
        const cookieArray = document.cookie.split(';');
        for(let i=0;i < cookieArray.length;i++)
        {
            let cookie = cookieArray[i];
            while (cookie.charAt(0)==' ')
            {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(target) == 0)
            {
                return cookie.substring(target.length, cookie.length);
            }
        }
        return null;
    }
}
